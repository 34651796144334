import React from "react";
import styles from "./NavDisplayCard.module.css";
import { BiSolidRightArrowAlt } from "react-icons/bi";

const NavDisplayCard = (props) => {
  const { navStatus, navigation } = props;
  return (
    <div className={styles.display}>
      <h1 className="heading">{navStatus}</h1>
      <p>
        {navigation ? "Explore" : "Home"} {<BiSolidRightArrowAlt />} {navStatus}
      </p>
    </div>
  );
};
export default NavDisplayCard;
