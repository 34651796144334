import React from "react";
import { PiSmileySadDuotone } from "react-icons/pi";

const ResultsNotFoundError = () => {
  return (
    <div className="container text-center mt-4  border border-dark w-75 pt-5 rounded">
      <h3 className="text-success">Sorry, We couldn't find any matches...</h3>
      <p className="fs-1">
        <PiSmileySadDuotone />
      </p>
    </div>
  );
};

export default ResultsNotFoundError;
