import React, { useContext, useEffect } from "react";
import useInputs from "../../hooks/useInputs";
import NavDisplayCard from "../../components/NavDisplayCard/NavDisplayCard";
import { useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import { AiFillInstagram } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { IoIosCall, IoLogoWhatsapp } from "react-icons/io";
import EnquiryNowSubmitAlert from "../../components/Alert/EnquiryNowSubmitAlert";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import packagesContext from "../../context/packagesContext";
import { Helmet } from "react-helmet-async";

const EnquireNow = ({ packageTitle }) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const context = useContext(packagesContext);
  useEffect(() => {
    context.setTopLoadingBarProgress(0);
    context.setTopLoadingBarProgress(100);
  }, []);

  const {
    enteredValue: enteredFullName,
    enteredValueChangeHandler: enteredFullNameChangeHandler,
    enteredValueBlurHandler: enteredFullNameBlurHandler,
    reset: resetFullName,
    enteredValueIsValid: enteredFullNameIsValid,
    enteredValueIsInvalid: enteredFullNameIsInvalid,
  } = useInputs((value) => value.trim().length >= 3);

  const {
    enteredValue: enteredEmail,
    enteredValueChangeHandler: enteredEmailChangeHandler,
    enteredValueBlurHandler: enteredEmailBlurHandler,
    reset: resetEmail,
    enteredValueIsValid: enteredEmailIsValid,
    enteredValueIsInvalid: enteredEmailIsInvalid,
  } = useInputs((value) => emailRegex.test(value));

  const {
    enteredValue: enteredMessage,
    enteredValueChangeHandler: enteredMessageChangeHandler,
    reset: resetMessage,
  } = useInputs((value) => value.trim() !== "");

  const {
    enteredValue: enteredPerson,
    enteredValueChangeHandler: enteredPersonChangeHandler,
    enteredValueBlurHandler: enteredPersonBlurHandler,
    reset: resetPerson,
    enteredValueIsValid: enteredPersonIsValid,
    enteredValueIsInvalid: enteredPersonIsInvalid,
  } = useInputs((value) => value >= 1 && value <= 10);

  const {
    enteredValue: enteredKid,
    enteredValueChangeHandler: enteredKidChangeHandler,
    enteredValueBlurHandler: enteredKidBlurHandler,
    reset: resetKid,
    enteredValueIsValid: enteredKidIsValid,
    enteredValueIsInvalid: enteredKidIsInvalid,
  } = useInputs((value) => value >= 0 && value <= 10);

  const {
    enteredValue: enteredContact,
    enteredValueChangeHandler: enteredContactChangeHandler,
    enteredValueBlurHandler: enteredContactBlurHandler,
    reset: resetContact,
    enteredValueIsValid: enteredContactIsValid,
    enteredValueIsInvalid: enteredContactIsInvalid,
  } = useInputs((value) => value.length === 10);

  const {
    enteredValue: enteredDate,
    enteredValueChangeHandler: enteredDateChangeHandler,
    enteredValueBlurHandler: enteredDateBlurHandler,
    reset: resetDate,
    enteredValueIsValid: enteredDateIsValid,
    enteredValueIsInvalid: enteredDateIsInvalid,
  } = useInputs((value) => value.trim() !== "");

  let location = useLocation();
  let minDate = new Date().toLocaleDateString("fr-ca");
  const form = useRef();

  const [CaptaVerified, setCaptaVerified] = useState(false);
  const captaOnChangeHandler = (value) => {
    setCaptaVerified(true);
  };

  let isFormValid = false;
  if (
    enteredFullNameIsValid &&
    enteredPersonIsValid &&
    enteredKidIsValid &&
    enteredDateIsValid &&
    enteredEmailIsValid &&
    enteredContactIsValid &&
    CaptaVerified
  ) {
    isFormValid = true;
  }

  const [showSubmitAlert, setShowSubmitAlert] = useState(false);
  const hideSubmitAlert = () => {
    setShowSubmitAlert(true);
    setTimeout(() => {
      setShowSubmitAlert(false);
    }, 10000);
  };
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
        process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAIL_JS_API_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    hideSubmitAlert();

    resetContact();
    resetDate();
    resetEmail();
    resetFullName();
    resetKid();
    resetMessage();
    resetPerson();
  };

  return (
    <>
      {location.pathname === "/enquiry-now" && (
        <>
          <Helmet>
            <title>
              Book Your Adventure Today| Contact Us| Hampitrip.com
            </title>
            <meta
              name="description"
              content="Embark on unforgettable journeys with HampiTrip- your premier choice for tour and travel packages. Contact us to start planning your next adventure today."
            />
            <link
          rel="canonical"
          href="https://hampitrip.com/enquiry-now"
        />
          </Helmet>
          <NavDisplayCard navStatus={"Enquiry Now"} />
        </>
      )}
      <MainContainer className="container d-flex">
        <form
          className="d-flex gap-2 flex-column mb-5 form-width"
          ref={form}
          onSubmit={sendEmail}
        >
          <h3 className="text-center">Request a QUOTE</h3>
          <input
            name="package_title"
            value={
              packageTitle
                ? packageTitle
                : "Eqnquire sent from 'Enquire Now' Section"
            }
            className="d-none"
          ></input>
          <div className="form-floating">
            <input
              required
              type="text"
              className="form-control"
              id="floatingInputInvalid"
              placeholder="Enter your name..."
              name="user_name"
              onChange={enteredFullNameChangeHandler}
              value={enteredFullName}
              onBlur={enteredFullNameBlurHandler}
              autoComplete="off"
            />
            <label htmlFor="floatingInputInvalid">
              {enteredFullNameIsInvalid ? (
                <p className="text-danger">Name should be MIN 3 characters </p>
              ) : (
                <p>Name</p>
              )}
            </label>
          </div>
          <div className="form-floating">
            <input
              required
              type="email"
              className="form-control"
              id="floatingInputInvalid"
              placeholder="name@example.com"
              name="user_email"
              onChange={enteredEmailChangeHandler}
              value={enteredEmail}
              onBlur={enteredEmailBlurHandler}
              autoComplete="off"
            />
            <label htmlFor="floatingInputInvalid">
              {enteredEmailIsInvalid ? (
                <p className="text-danger">Please enter valid Email</p>
              ) : (
                <p>Email</p>
              )}
            </label>
          </div>
          <textarea
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            placeholder="Type your message here"
            style={{ resize: "none" }}
            name="user_description"
            onChange={enteredMessageChangeHandler}
            value={enteredMessage}
            autoComplete="off"
          ></textarea>
          <div className="d-flex justify-content-between gap-2">
            <div className="form-floating w-50">
              <input
                required
                type="number"
                className="form-control"
                placeholder="Enter number of persons..."
                id="floatingInputInvalid"
                name="user_peoples"
                min="1"
                max="10"
                onChange={enteredPersonChangeHandler}
                value={enteredPerson}
                onBlur={enteredPersonBlurHandler}
                autoComplete="off"
              />
              <label htmlFor="floatingInputInvalid">
                {enteredPersonIsInvalid ? (
                  <p className="text-danger">Min:1, Max:10</p>
                ) : (
                  <p>Adults</p>
                )}
              </label>
            </div>
            <div className="form-floating w-50">
              <input
                type="number"
                className="form-control"
                id="floatingInputInvalid"
                name="user_child"
                placeholder="Enter number of kids..."
                min="0"
                max="10"
                onChange={enteredKidChangeHandler}
                value={enteredKid}
                onBlur={enteredKidBlurHandler}
                autoComplete="off"
              />
              <label htmlFor="floatingInputInvalid">
                {enteredKidIsInvalid ? (
                  <p className="text-danger">Min:0, Max:10</p>
                ) : (
                  <p>Child</p>
                )}
              </label>
            </div>
          </div>
          <div className="d-flex justify-content-between gap-2">
            <div className="form-floating w-50">
              <input
                required
                type="number"
                className="form-control"
                id="floatingInputInvalid"
                name="user_phone"
                placeholder="Enter contact number..."
                onChange={enteredContactChangeHandler}
                value={enteredContact}
                onBlur={enteredContactBlurHandler}
                autoComplete="off"
              />
              <label htmlFor="floatingInputInvalid">
                {enteredContactIsInvalid ? (
                  <p className="text-danger">Should be 10 digits</p>
                ) : (
                  <p>Contact</p>
                )}
              </label>
            </div>
            <div className="form-floating w-50">
              <input
                required
                type="date"
                className="form-control"
                id="floatingInputInvalid"
                name="user_traveldate"
                onChange={enteredDateChangeHandler}
                value={enteredDate}
                onBlur={enteredDateBlurHandler}
                min={minDate}
              />
              <label htmlFor="floatingInputInvalid">
                {enteredDateIsInvalid ? (
                  <p className="text-danger">Please choose date</p>
                ) : (
                  <p>Travel Date</p>
                )}
              </label>
            </div>
          </div>
          <div style={{ margin: "0 auto" }}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={captaOnChangeHandler}
            />
          </div>
          <button
            disabled={!isFormValid}
            className="btn btn-primary"
            type="submit"
            value="Send"
          >
            Send
          </button>
          {showSubmitAlert && (
            <div class="alert alert-success position-sticky" role="alert">
              {" "}
              &#10003; Enquiry sent..
            </div>
          )}
        </form>
        <div className="containe mb-3">
          <h3 className="text-center">How It Works</h3>
          <ol className="mt-3">
            <li>Tell us details of your holiday plan.</li>
            <li>
              After you submit the form, one of our travel experts will get back
              to you with customised holiday package based on your requirement,
              within 24 hours.
            </li>
            <li>
              Grab the deal and start packing your bags for an indelible holiday
              with Hampitrip.com
            </li>
          </ol>
          <div className="container text-center">
            <span className="fs-1 px-1">
              <a
                href="https://api.whatsapp.com/send/?phone=%2B917019864996&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                <IoLogoWhatsapp />
              </a>
            </span>
            <span className="fs-1  px-1">
              <a
                href="https://www.instagram.com/hampitrip.hampi/"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillInstagram />
              </a>
            </span>
            <span className="fs-1  px-1">
              <a
                href="https://twitter.com/hampitrip"
                target="_blank"
                rel="noreferrer"
              >
                <FaXTwitter />
              </a>
            </span>
            <span className="fs-1  px-1">
              <a
                href="https://www.facebook.com/HampiTrip/"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookF />
              </a>
            </span>
            <div>
              <span>
                <IoIosCall /> Call Us for details
              </span>
              <h3>+91 7019864996</h3>
            </div>
            {showSubmitAlert && <EnquiryNowSubmitAlert className="w-40" />}
          </div>
        </div>
      </MainContainer>
    </>
  );
};

export default EnquireNow;
const MainContainer = styled.div`
  .form-width {
    min-width: 50%;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;
