import React from "react";

const Tags = () => {
  const tag = ["Popular", "Honeymoon", "Family", "Adventure"];
  return (
    <>
      {tag.map((t) => (
        <span
          key={t}
          className="badge rounded-pill text-bg-secondary my-2 mx-1"
        >
          {t}
        </span>
      ))}
    </>
  );
};

export default Tags;
