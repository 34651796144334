import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { Link, NavLink } from "react-router-dom";
import styles from "./Navbar.module.css";
const Navbar = () => {
  return (
    <nav className="navbar fixed-top navbar-expand-lg bg-body-tertiary position-sticky nav-pills">
      <div className="container-fluid position-sticky">
        <Link className={styles["navbar-brand"]} to="/">
          <h1 className={styles.logo}>Hampitrip.com</h1>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          aria-expanded="false"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse position-sticky"
          id="navbarNav"
        >
          <ul className="navbar-nav mt-2 mb-1 gap-1 align-items-center">
            <li className="nav-item">
              <NavLink
                className={({ isActive }) =>
                  `nav-link px-3 ${isActive ? "active text-white" : ""}`
                }
                aria-current="page"
                to="/"
              >
                HOME
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                className={({ isActive }) =>
                  `nav-link px-2 text-center ${
                    isActive ? "active text-white" : ""
                  }`
                }
                to="/tour-packages"
              >
                TOUR PACKAGES
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={({ isActive }) =>
                  `nav-link px-2 text-center  ${
                    isActive ? "active text-white" : ""
                  }`
                }
                to="/bus-packages"
              >
                BUS PACKAGES
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={({ isActive }) =>
                  `nav-link px-2 text-center  ${
                    isActive ? "active text-white" : ""
                  }`
                }
                to="/hotels"
              >
                HOTELS
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={({ isActive }) =>
                  `nav-link px-2 text-center  ${
                    isActive ? "active text-white" : ""
                  }`
                }
                to="/car-rental"
              >
                CAR RENTAL
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={({ isActive }) =>
                  `nav-link px-2 text-center  ${
                    isActive ? "active text-white" : ""
                  }`
                }
                to="about-us"
              >
                ABOUT US
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={({ isActive }) =>
                  `nav-link px-2 text-center ${
                    isActive ? "active text-white" : ""
                  }`
                }
                to="/enquiry-now"
              >
                ENQUIRY NOW
              </NavLink>
            </li>
          </ul>
        </div>
        <span className="d-flex justify-content-center align-items-center">
          <div>
            <span style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <AiOutlineMail />
              info@hampitrip.com
            </span>
            <span style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <BsTelephoneFill />
              +91 70198 64996
            </span>
          </div>
        </span>
      </div>
    </nav>
  );
};

export default Navbar;
