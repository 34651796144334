import React from "react";

const EnquiryNowSubmitAlert = () => {
  return (
    <div class="alert alert-success position-sticky" role="alert">
      <h4>Thank You !!</h4>
      <p>
        Congratulations, your query has been submitted successfully. You'll soon
        be contacted by our representative after we have processed your details.
        This normally takes 1-4 working days.
      </p>

      <i>Important:</i>
      <p>
        In case you do not receive our reply or acknowledgement with in 24
        hours, it means we have not received your communication and we request
        you to resend your query at following e-mail id : enquiry@hampitrip.com
      </p>
    </div>
  );
};

export default EnquiryNowSubmitAlert;
