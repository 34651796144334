import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const SubscribeCard = () => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const [email, setEmail] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const [emailExist, setEmailExist] = useState(false);

  let emailIsValid = emailRegex.test(email);
  const emailIsInvalid = !emailIsValid && emailTouched;

  let isFormValid = false;
  if (emailIsValid && !emailExist) {
    isFormValid = true;
  }
  const [subscridedMsg, setSubscridedMsg] = useState(false);
  const showSubmitAlert = () => {
    setSubscridedMsg(true);
    setTimeout(() => {
      setSubscridedMsg(false);
    }, 5000);
  };

  const emailChangeHandler = (event) => {
    setEmail(event.target.value);
  };
  const emailBlurHandler = (event) => {
    setEmailTouched(true);
  };
  const onSubmitFormHandler = (event) => {
    event.preventDefault();

    addEmailToSubscriberList(email);
    showSubmitAlert();
    setEmail("");
    setEmailTouched(false);
  };
  const addEmailToSubscriberList = async (email) => {
    await fetch(process.env.REACT_APP_SUBSCRIBERS_API_KEY, {
      method: "POST",
      body: JSON.stringify(email),
      header: {
        "Content-Type": "application/json",
      },
    });
  };
  useEffect(() => {
    setEmailExist(false);

    const isEmailExist = async () => {
      let response = await fetch(process.env.REACT_APP_SUBSCRIBERS_API_KEY);
      const data = await response.json();
      for (const key in data) {
        if (data[key] === email) {
          setEmailExist(true);
        }
      }
    };
    isEmailExist();
  }, [email]);

  return (
    <Container className="blue-gradient-bg hide">
      <div>
        <div className="message">
          <h3>Save time, save money!</h3>
          <p>Sign up and we'll send the best deals to you</p>
        </div>
        <form onSubmit={onSubmitFormHandler}>
          <span className="form-floating ">
            <input
              type="email"
              className="form-control"
              id="floatingInputInvalid"
              name="email"
              placeholder="Your email address"
              onBlur={emailBlurHandler}
              onChange={emailChangeHandler}
              value={email}
              autoComplete="off"
            />
            <label htmlFor="floatingInputInvalid">
              {emailIsInvalid ? (
                <p className="text-danger">Please enter valid email address</p>
              ) : emailExist ? (
                <p className="text-success">You are already subscribed</p>
              ) : (
                <p> Your email address</p>
              )}
            </label>
          </span>
          <button
            disabled={!isFormValid}
            type="submit"
            className="btn btn-primary"
          >
            {subscridedMsg ? (
              <span className="text-light">
                &#128515; Thank you for Subscribe!!
              </span>
            ) : (
              <span>Subscribe</span>
            )}
          </button>
          {/* {subscridedMsg && <p className="text-success"> Thank you for Subscribe!!</p>} */}
        </form>
      </div>
    </Container>
  );
};

export default SubscribeCard;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 20px 0;
  transition: opacity 0.2s ease;
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .hide {
    opacity: 0;
  }
`;
