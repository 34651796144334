import {
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import PublicRoutesLayout from "./PublicRoutesLayout";

import { Route } from "react-router-dom";
import React, { Suspense } from "react";
import { LoadingSpinner } from "../components";
const Home = React.lazy(() => import("../pages/Home/Home"));
const ViewPackageDetails = React.lazy(() =>
  import("../pages/ViewPackageDetails/ViewPackageDetails")
);
const EnquireNow = React.lazy(() => import("../pages/EnquireNow/EnquireNow"));
const BusPackages = React.lazy(() =>
  import("../pages/BusPackages/BusPackages")
);
const TandC = React.lazy(() => import("../pages/TandC/TandC"));
const CancelPolicy = React.lazy(() =>
  import("../pages/CancelPolicy/CancelPolicy")
);
const Privacy = React.lazy(() => import("../pages/PrivacyPolicy/Privacy"));
const Disclaimer = React.lazy(() =>
  import("../pages/DisclaimerPolicy/Disclaimer")
);
const CarRental = React.lazy(() => import("../pages/CarRental/CarRental"));
const TourPackages = React.lazy(() =>
  import("../pages/TourPackages/TourPackages")
);
const About = React.lazy(() => import("../pages/About/About"));
const PageNotFound = React.lazy(() =>
  import("../pages/PageNotFound/PageNotFound")
);
const Explore = React.lazy(() => import("../pages/Explore/Explore"));
const Hotels = React.lazy(() => import("../pages/Hotels/Hotels"));

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route>
        {/* Public Routes */}
        <Route path="/" element={<PublicRoutesLayout />}>
          {/* Navbar Pages */}
          <Route
            path="/"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="/tour-packages"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <TourPackages />
              </Suspense>
            }
          />
          <Route
            path="/tour-packages/:slug"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <ViewPackageDetails />{" "}
              </Suspense>
            }
          />
          <Route
            path="/bus-packages"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <BusPackages />
              </Suspense>
            }
          />
          <Route
            path="/bus-packages/:slug"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <ViewPackageDetails />
              </Suspense>
            }
          />
          <Route
            path="/hotels"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Hotels />
              </Suspense>
            }
          />
          <Route
            path="/car-rental"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <CarRental />
              </Suspense>
            }
          />
          <Route
            path="/about-us"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <About />
              </Suspense>
            }
          />
          <Route
            path="/enquiry-now"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <EnquireNow />
              </Suspense>
            }
          />

          {/*Footer Pages*/}
          <Route
            path="/disclaimer-policy"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Disclaimer />
              </Suspense>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Privacy />
              </Suspense>
            }
          />
          <Route
            path="/cancellation-refund-policy"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <CancelPolicy />
              </Suspense>
            }
          />
          <Route
            path="/terms-conditions"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <TandC />
              </Suspense>
            }
          />

          {/*TopFooter-Explore*/}
          <Route
            path="/explore/:slug"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Explore />
              </Suspense>
            }
          />

          {/* Page Not Found */}
          {/* Login Page */}
        </Route>
        <Route
          path="*"
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <PageNotFound />
            </Suspense>
          }
        />
      </Route>
    </>
  )
);
