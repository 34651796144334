import React from "react";
import styled from "styled-components";
import { GrAnnounce } from "react-icons/gr";
import { useContext } from "react";
import packagesContext from "../../context/packagesContext";
import { Link } from "react-router-dom";
import { convertToSlug } from "../../functions";

const Announcement = () => {
  const context = useContext(packagesContext);
  const slug = convertToSlug(context?.announcement[0]?.offer?.packageTitle);
  return (
    <Container>
      <div className="box">
        <div className="image" alt="news">
          <img
            src={context?.announcement[0]?.news?.iconUrl}
            alt="announcement"
            loading="eager"
          />
        </div>
        <p className="text-wrap break-word">
          <GrAnnounce /> {context?.announcement[0]?.news?.description}
        </p>
      </div>
      <Link
        to={`/tour-packages/${slug}`}
        className="box text-decoration-none text-dark"
      >
        <div className="image" alt="offer">
          <img
            src={context?.announcement[0]?.offer?.iconUrl}
            alt="offer"
            loading="eager"
          />
        </div>
        <p className="text-wrap break-word">
          <strong className="text-primary">BEST EVER DEAL : </strong>{" "}
          {context?.announcement[0]?.offer?.description}
        </p>
      </Link>
    </Container>
  );
};

export default Announcement;

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 3px;
  margin: 10px 0;

  .box {
    border: 1px solid grey;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 5px;
    width: 50%;
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }
  .box:hover {
    box-shadow: 0 0 3px black;
  }
  img {
    height: 50px;
    width: 55px;
    object-fit: cover;
    border-radius: 5px;
    @media only screen and (max-width: 600px) {
    }
  }
  Link {
    text-decoration: none;
    color: black;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;
