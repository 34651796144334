export const convertToSlug = (text) => {
  return text
    ?.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const convertSlugtoTitle = (slug) => {
  var words = slug?.split("-");
  return words
    ?.map(function (word) {
      return word?.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
    })
    .join(" ");
};
